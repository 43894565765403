import axios, {InternalAxiosRequestConfig} from "axios";
import {getToken} from "./token";
import {getLang} from "./user";
import { useNavigate} from "react-router-dom";
export const request = axios.create({
    baseURL: 'https://api.the-kids.org',
    timeout: 600000,
})

request.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const token = getToken()
    if (token) {
        config.headers['X-Token'] = token
    }
    const lang = getLang()
    if (lang) {
        config.headers['lang'] = lang
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

request.interceptors.response.use((response) => {
    return response
}, (error) => {
    if ((error?.response.status === 500 && error?.response?.data?.message === '401') || error?.response.status === 401) {
        // 返回登录
        // let nativate = useNavigate();
        // nativate('/login')
        // window.location.hash="/login"
        window.location.pathname = '/login'
    }

    return Promise.reject(error)
})